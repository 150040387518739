import {
  useEffect,
  useState,
} from 'react';

import * as Amplitude from '@amplitude/analytics-browser';

let existsAmplitude;
let alreadyInit = false;

export default function useAmplitude() {
  const [
    amplitude,
    setAmplitude,
  ] = useState(existsAmplitude);

  useEffect(() => {
    if (alreadyInit || existsAmplitude) {
      return;
    }

    alreadyInit = true;

    Amplitude.init('2d6199fade8c19d60e443378ad093cb0', {
      defaultTracking: false,
      logLevel: 0,
    }).promise.then(() => {
      existsAmplitude = Amplitude;
      setAmplitude(Amplitude);
    }).catch(() => {
      alreadyInit = false;
    });
  }, []);

  return amplitude;
};