import React, {
  useEffect,
  useState,
} from 'react';

import {
  useFormik,
} from 'formik';

import Page from './Page';
import { validationSchema } from './validation-scheme';
import useAmplitude from '../../hooks/use-amplitude';
import styles from './style.module.scss';

export default function SignIn() {
  const amplitude = useAmplitude();

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      if (amplitude) {
        amplitude.track('signin_continue_pressed');
      }
  
      fetch('/users/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }).then((response) => response.json()).then((data) => {
        let errorMessage = 'Access token not found in response';

        if (typeof data === 'object' && data !== null) {
          if (data.access_token) {
            localStorage.setItem('access_token', data.access_token);
            window.location.href = '/';
            return;
          }

          if (data.detail) {
            errorMessage = data.detail;
          }
        }
        
        throw new Error(errorMessage);
      }).catch((error) => {
        formik.setErrors({
          email: error.message,
        });

        formik.setSubmitting(false);
      });
    },
  });

  const [
    showPassword,
    setShowPassword,
  ] = useState(false);

  useEffect(() => {
    if (amplitude) {
      amplitude.track('signin_presented');
    }
  }, [
    amplitude,
  ]);

  const onToggleShowPassword = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  const button = (
    <button
      className={styles.action}
      onClick={formik.submitForm}
      disabled={formik.isSubmitting}
      type="button"
    >
      Continue
    </button>
  );

  let emailClassName = `${styles.input} ${styles.email}`;
  let emailError;

  if (formik.touched.email && formik.errors.email) {
    emailClassName += ` ${styles.hasError}`;
    emailError = (
      <div className={styles.error}>
        {formik.errors.email}
      </div>
    );
  }

  let passwordClassName = `${styles.input} ${styles.password}`;
  let passwordType = 'password';
  let passwordError;

  if (formik.touched.password && formik.errors.password) {
    passwordClassName += ` ${styles.hasError}`;
    passwordError = (
      <div className={styles.error}>
        {formik.errors.password}
      </div>
    );
  }

  if (showPassword) {
    passwordClassName += ` ${styles.show}`;
    passwordType = 'text';
  }

  return (
    <Page button={button}>
      <div className={styles.title}>
        Sign in
      </div>
      <div className={styles.subtitle}>
        Sign in to access your library of watch faces
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={emailClassName}>
          <input
            autoComplete="off"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter your email address"
            value={formik.values.email}
          />  
        </div>
        {emailError}
        <div className={passwordClassName}>
          <input
            autoComplete="off"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter your password"
            type={passwordType}
            value={formik.values.password}
          />
          <button
            onClick={onToggleShowPassword}
            type="button"
          />
        </div>
        {passwordError}
      </form>
    </Page>
  );
}