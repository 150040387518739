import React, { useEffect, useState } from "react";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { purchaseEvent } from "../utils/PixelEvents";


const StripeCheckoutForm2 = ({ urlPart, hashedValue, setErrorComponentState, setPaymentFailed, setStripeError, priceToMeta, setCheckoutIsReady, bannerOffer }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const options = {
        layout: {
            maxColumns: 1,
            maxRows: 5,
        },
        wallets: {
            applePay: 'always',
        },
        buttonTheme: {
            applePay: 'white',
            paypal: 'gold'
        },
        paymentMethodOrder: ['apple_pay']
    };


    const onReady = ({availablePaymentMethods}) => {
        if (!availablePaymentMethods) {
        } else {
            setCheckoutIsReady(true);
        }
    }

    const onConfirm = async () => {

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const countryCode = window.localStorage.getItem("country_code");

        alert(Object.values(elements));

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://watchfaces.mobitopia.co`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: countryCode
                        }
                    }
                }
            },
            // redirect: 'if_required',
        });

        if (!error) {
            Array.from(document.querySelectorAll(".add-get-button")).forEach((element) => {
                element.classList.add("get-face-btn");
                element.innerText = "Download";
            });
            document.cookie = 'bought=true;max-age=31536000';
            purchaseEvent(priceToMeta);
            return;
        }
        if (error && (error.type === "card_error" || error.type === "validation_error")) {
            setMessage(error.message);
            setStripeError(error.message);
            if (error.code === 'card_declined') {
                setErrorComponentState(true);
                setPaymentFailed(true);
                setStripeError(error.message);
            }
        } else {
            alert(error.message);
            setErrorComponentState(true);
            setPaymentFailed(true);
            setMessage("An unexpected error occurred.");
            setStripeError("An unexpected error occurred.");
        }

        setIsLoading(false);
    }

    return (
        <div id="checkout-page">
            <div id="express-checkout-element">
                    <ExpressCheckoutElement 
                        onConfirm={onConfirm} 
                        options={options} 
                        onReady={onReady} 
                    />
            </div>
        </div>
    );
};

export default StripeCheckoutForm2;