import { useState, useEffect } from "react";
import MainModal from "./modals/MainModal";
import amplitudeEventsSender from "./utils/AmplitudeEvents";
import { purchaseEvent, initiateCheckoutEvent } from "./utils/PixelEvents";
import PaginationComponent from "./PaginationComponent";
import EmailConfirmationModal from "./modals/EmailConfirmationModal";
import ChangeEmailModal from "./modals/ChangeEmailModal";

const WatchfacesGallery = (props) => {
    const [previewComponentState, setPreviewComponentState] = useState(false);
    const [stripeComponentState, setStripeComponentState] = useState(false);
    const [faceName, setFaceName] = useState("");
    const [imageName, setImageName] = useState("");
    const [readyToBuy, setReadyToBuy] = useState(false);
    const [strap, setStrap] = useState("");

    const [premium, setPremium] = useState(false);

    const [bannerOffer, setBannerOffer] = useState(false);

    // const [showPopUp, setShowPopUp] = useState(false);

    const handleModal = () => {
        if (stripeComponentState) {
            return;
        }
        amplitudeEventsSender({
            event_name: "preview_screen_closed",
        });
        setPreviewComponentState(!previewComponentState);
        props.handleModal();
    };

    const intentClientSecret = new URLSearchParams(window.location.search).get("payment_intent");
    const userEmail = new URLSearchParams(window.location.search).get("email");
    const accessCode = new URLSearchParams(window.location.search).get("access_code") || new URLSearchParams(window.location.search).get("acces_code");


    const setShowBanner = props.setShowBanner;

    useEffect(() => {
        if (accessCode) {
            const fetchUserFromCode = async () => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ code: accessCode })
                };

                const response = await fetch("/users/get-token", requestOptions);
                const data = await response.json();
                if (response.ok) {
                    localStorage.setItem("access_token", data.access_token);
                    window.location.href = "/";
                }
            };
            fetchUserFromCode();
        }
    }, []);

    useEffect(() => {

        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            const fetchUser = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                },
            };
            const response = await fetch("/users/me", requestOptions);
            const data = await response.json();
            if (response.ok) {
                props.setSignedIn(true);
            } else {
                localStorage.removeItem("access_token");
            }
            try {
                if (data) {
                    localStorage.setItem('email_signup', data.email);
                    if (!data.premium && window.location.pathname.includes("premium")) {
                        window.location.href = window.location.origin;
                    }
                }
                if (data && data.premium) {
                    setShowBanner(false);
                    setPremium(data.premium);
                    localStorage.setItem("premium", data.premium);
                    Array.from(document.querySelectorAll(".add-get-button")).forEach((element) => {
                        element.classList.add("get-face-btn");
                        element.innerText = "Download";
                    });
                    if (window.location.pathname === "/" && !window.location.pathname.includes("premium")) {
                        window.location.href = window.location.origin + "/premium";
                    }
                }
            } catch(e) {
                console.log(e);
            }
            };
            fetchUser();
        } else {
            props.setSignedIn(false);
            localStorage.removeItem("email_signup");
            if (window.location.pathname.includes("premium")) {
                window.location.href = window.location.origin;
            }
        };
    }, []);

    const [renderStates, setRenderStates] = useState([]);

    const facesLen = props.facesLen;
    const batchSize = 10;
    const delayBetweenBatches = 5000; // 5 seconds

    useEffect(() => {
        const initializeRenderStates = () => {
            const initialRenderStates = Array.from({ length: facesLen }, (_, index) => ({
                render: index < batchSize,
            }));
            setRenderStates(initialRenderStates);
        };

        initializeRenderStates();
    }, [facesLen]); // Run once on mount

    useEffect(() => {
        let currentBatchStartIndex = batchSize;
        const intervalId = setInterval(() => {
            setRenderStates((prevStates) => {
                const newRenderStates = prevStates.map((prevState, index) => ({
                    render: index < currentBatchStartIndex + batchSize,
                }));
                currentBatchStartIndex += batchSize;
                return newRenderStates;
            });
        }, delayBetweenBatches);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Run once on mount
    //

    // const handleBannerClick = (e) => {
    //     if (!premium) {
    //         props.setActiveMainModal(true);
    //         e.preventDefault();
    //         window.localStorage.setItem("faceName", faceName);
    //         window.localStorage.setItem("readyToBuy", true);
    //         setPreviewComponentState(false);
    //         setStripeComponentState(true);
    //         setReadyToBuy(true);
    //         initiateCheckoutEvent();
    //         setBannerOffer(true);
    //     }
    // }

    return (
        <>
            {/* <div className="banner-offer" onClick={handleBannerClick}></div> */}
            <MainModal
                formats={props.formats}
                login={props.login}
                activeModalState={props.activeModalState}
                setActiveMainModal={props.setActiveMainModal}
                handleModal={handleModal}
                redirectEndpoint={props.redirectEndpoint}
                facebookCallback={props.facebookCallback}
                checkUserSessionStatus={props.checkUserSessionStatus}
                preview={previewComponentState}
                setPreview={setPreviewComponentState}
                faceName={faceName}
                imageName={imageName}
                userLoggedIn={props.userLoggedIn}
                setReadyToBuy={setReadyToBuy}
                readyToBuy={readyToBuy}
                payment={stripeComponentState}
                setPaymentState={setStripeComponentState}
                // previewUrl={props.url}
                preloaded={props.preloaded}
                facesUrl={props.facesUrl}
                strapsUrl={props.strapsUrl}
                strap={strap}
                bannerOffer={bannerOffer}
                setBannerOffer={setBannerOffer}
                premium={premium}
            />
            <ChangeEmailModal
                showPopUp={props.showChangeEmailPopup}
                setShowChangeEmailPopup={props.setShowChangeEmailPopup}
                email={userEmail ? userEmail : ""}
            />
            <PaginationComponent 
                faces={props.faces}
                facesUrl={props.facesUrl}
                strapsUrl={props.strapsUrl}
                setStrap={setStrap}
                setActiveMainModal={props.setActiveMainModal}
                setPreviewComponentState={setPreviewComponentState}
                setFaceName={setFaceName}
                setImageName={setImageName}
                userLoggedIn={props.userLoggedIn}
                premium={premium}
            />
        </>
    );
};

export default WatchfacesGallery;
