import { styled } from "@mui/material/styles";

const Item = styled("div")(({ theme }) => ({
    // background: "linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434",
    // ...theme.typography.body2,
    color: "#ffffff",
    padding: theme.spacing(2),
    textAlign: "center",
    // height: theme.spacing(30)
    // color: theme.palette.text.secondary,
}));

export default Item;