import React from 'react';
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Vector from "../images/Vector.png"
import CardPayment from "../images/card-payment.png";
import ExpressFiller from "../images/express-pay-filler.png";
import StripeCheckoutForm from "../components/forms/StripeCheckoutForm";
import StripeCheckoutForm2 from "../components/forms/StripeCheckoutForm2"



const stripePromise = loadStripe(
    "pk_test_51MCMD3FLF5P5YxU3Cfaf9OqugIqixVlNO5h2SxeNcHoLBFaOrrPTe0Mz43t9oxL7HuqUiG5s7Z3KehSW62rBl87U00ugIP8ZUT"
    // "pk_live_51MCMD3FLF5P5YxU3MADNQa6uDEpZfr7y2GdktJT7shnNEs8jQ2TLzpwDxWrwlVgd3Qpzmg0fJnMBWjAIiJ6pJtPF00dl3xHDWt"
);

const Payment = () => {
    const [clientSecret, setClientSecret] = useState("");


    const [stripeError, setStripeError] = useState("");

    const [priceToShow, setPriceToShow] = useState("");

    const [paymentFailed, setPaymentFailed] = useState(false);

    const [errorComponentState, setErrorComponentState] = useState(false);

    const [useCard, setUseCard] = useState(false);


    const [showCardButton, setShowCardButton] = useState('hidden');

    const [checkoutIsReady, setCheckoutIsReady] = useState(false);

    const handleCardPayClick = () => {
        setUseCard(!useCard);
        setShowCardButton('none');
    }


    useEffect(() => {
        const fetchCreatePayment = async () => {
            
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: "payment.mobitopia.co" }),
            };

            const response = await fetch("/api/create-payment-intent", requestOptions);
            const result = await response.json();
            setClientSecret(result.clientSecret);

            setPriceToShow(result.priceToShow);
            setShowCardButton("initial");
        };

        fetchCreatePayment().catch(console.error);
    }, []);

    const appearance = {
        theme: 'stripe',
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#A6A6A6',
            colorPrimary: 'black',
            accessibleColorOnColorPrimary: 'black',
            colorText: 'black',
            colorTextSecondary: 'black',
            colorTextPlaceholder: 'black',
            tabIconColor: 'black',
            logoColor: 'dark',
        },
        rules: {
            '.Input, .Block': {
            backgroundColor: 'transparent',
            border: '1.5px solid var(--colorPrimary)'
            }
        }
    };

    const options = { clientSecret, appearance };

    return (
        <>
            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <div className='payment-content' style={{margin: "6vw"}}>
                    {!paymentFailed
                    ? (
                        <>
                            <div>
                                <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important", fontWeight: 700, fontSize: "6vw", color: "black"}} className="payment-header mb-2" variant="h6" component="h2">
                                    <p>Complete your purchase</p>
                                </Typography>
                                <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important", lineHeight: "6vw"}} className="payment-second-header" variant="h8" component="h2">
                                    <p>Watch Faces Collection - Dynamic, Live & <br /> Customizable Styles - Weekly Updates</p>
                                </Typography>
                            </div>
                            <div className="mt-5 payment-offer mb-4" style={{borderTop: "0.2vw solid black", borderBottom: "0.2vw solid black", fontSize: "4.5vw", color: "black"}}>
                                <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                    <div>
                                        Lifetime Access<br />
                                        to Watch Faces Collection
                                    </div>
                                    <div>
                                        $23.99
                                    </div>
                                </div>
                                <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                    <div>
                                        Introductory Offer
                                    </div>
                                    <div>
                                        <div style={{backgroundColor: "black", color: "white", paddingLeft: "1vw", paddingRight: "1vw"}}>
                                            – $13.00
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 payment-total mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", color: "black", fontSize: "5.5vw", fontWeight: 700}}>
                                <div>
                                    Total
                                </div>
                                <div>
                                    $10.99
                                </div>
                            </div>
                            <div className="guarantee-part mt-4 w-100 mb-4">
                                <img style={{width: "4vw"}} src={Vector} alt=""/>
                                <span style={{fontSize: "3.5vw", color: "black"}}>Secure checkout</span>
                                <div className="stripe-text-block">
                                    Powered by <span style={{fontWeight:"700"}}>stripe</span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important"}} className="payment-header" variant="h6" component="h2">
                            <p>❌ Oops, Payment is failed</p>
                        </Typography>
                        )
                    }
                    {!errorComponentState 
                    ? (
                        <Typography id="transition-modal-description" sx={{ mt: 2 }} component={"span"}>
                            <div className="checkout-forms">
                                {clientSecret && (
                                    <Elements options={options} stripe={stripePromise} key={clientSecret}>
                                        <StripeCheckoutForm
                                        clientSecret={clientSecret}
                                        setErrorComponentState={setErrorComponentState}
                                        setPaymentFailed={setPaymentFailed}
                                        setStripeError={setStripeError}
                                        />
                                    </Elements>
                                )}
                                {!clientSecret && <div className="spinner" id="spinner"></div>}
                            </div>
                        </Typography>
                    ) : (
                        <>
                            {clientSecret && (
                                <Elements options={options} stripe={stripePromise} key={clientSecret}>
                                    <StripeCheckoutForm
                                    clientSecret={clientSecret}
                                    setErrorComponentState={setErrorComponentState}
                                    setPaymentFailed={setPaymentFailed}
                                    setStripeError={setStripeError}
                                    />
                                </Elements>
                            )}
                        </>
                    )
                    }
                </div>
            </div>
        </>
    );
};


export default Payment;