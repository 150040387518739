import React from 'react';
import Onboarding from '../../layouts/Onboarding';
import Logo from './logo.png';
import styles from './style.module.scss';

export default function Page({ 
  button,
  children,
}) {
  return (
    <Onboarding>
      <div className={styles.content}>
        <div className={styles.app}>
          <div className={styles.logo}>
            <img
              alt="logo"
              src={Logo}
            />
          </div>
          <div className={styles.texts}>
            <div className={styles.name}>
              Watch Faces
            </div>
            <div className={styles.gallery}>
              GALLERY
            </div>
          </div>
        </div>
        <div className={styles.form}>
          {children}
        </div>
      </div>
      {button && (
        <div className={styles.control}>
          {button}
        </div>
      )}
    </Onboarding>
  );
}