import React, { useState, useEffect } from 'react';
import { Modal, Fade, Box } from '@mui/material';


const ChangeEmailModal = ({ showPopUp, email, setShowChangeEmailPopup }) => {

    const buttonStyle = {
        position: 'absolute',
        left: "0px",
        top: "0px",
        zIndex: 1,
        width: "100%",
        height: "100%",
        margin: "0px",
        padding: "0px",
        border: "none",
        fontSize: "0px",
        background: "transparent",
        cursor: "pointer",
        opacity: "0"
    }

    const [inputEmail, setInputEmail] = useState(email);
    const [isEmailValid, setIsEmailValid] = useState(false);

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setInputEmail(newEmail);
        validateEmail(newEmail);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setIsEmailValid(emailRegex.test(email));
    };

    useEffect(() => {
        if(inputEmail) {
            validateEmail(inputEmail);
        }
    }, []);

    const changeEmail = async () => {
        if (!isEmailValid) {
            return;
        }
        const accessToken = localStorage.getItem("access_token");
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json', 
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ 
                email: inputEmail,
                premium: true,
            })
        };
        const response = await fetch("/users/update-email", requestOptions);
        const data = await response.json();

        if (response.ok) {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("email_signup", inputEmail);
            window.location.href = "/";
        }
    }

    const onCloseModal = () => {
        setShowChangeEmailPopup(false);
    }

    const style = {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "100%",
        width: "50%",
        maxWidth: "554px",
        height: "100%",
        minHeight: "600px",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "20px 20px 0 0",
        background: "#F7F7F7",
        outline: 0,
        paddingTop: "12px",
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showPopUp}
                onClose={onCloseModal}
                closeAfterTransition
                hideBackdrop={true}
                >
                    <Fade in={showPopUp}>
                        <Box sx={style}>
                            <div className='email-conf-modal'>
                                <div className="close-button close-change-email">
                                    <button type="button" onClick={onCloseModal} style={buttonStyle}></button>
                                    <span>
                                        <span className="bm-burger-cross-change-email" style={{position: "absolute", transform: "rotate(45deg)"}}></span>
                                        <span className="bm-burger-cross-change-email" style={{position: "absolute", transform: "rotate(-45deg)"}}></span>
                                    </span>
                                </div>
                                <div className="email-header">
                                    Change account email
                                </div>
                                <div className="email-subheader">
                                    Current email: {localStorage.getItem("email_signup")}
                                </div>
                                <div className="email-support-text">
                                    You can change your email address here if it was set up incorrectly or you would prefer to use a different one.
                                </div>
                                <div className="email-input">
                                    <label htmlFor="email-input">
                                        New Email
                                    </label>
                                    <input
                                        id="email-input"
                                        className={`input-field ${isEmailValid ? '' : 'invalid'}`}
                                        value={inputEmail}
                                        onChange={handleEmailChange}
                                        placeholder='mail@example.com'
                                    />
                                    {!isEmailValid && (
                                    <div className="error-message">
                                        Please enter a valid email address.
                                    </div>
                                )}
                                </div>
                                <button
                                    className="button is-success confirm-email"
                                    onClick={changeEmail} 
                                    disabled={!isEmailValid}
                                >
                                    Change Email
                                </button>
                            </div>
                        </Box>
                    </Fade>
            </Modal>
        </div>
    );
};

export default ChangeEmailModal;