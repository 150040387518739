import React from 'react';

const SecondBanner = (props) => {

    const onClose = () => {
        localStorage.removeItem("welcomeBannerStage");
        props.setShowFirstBanner(false);
        props.setShowSecondBanner(false);
        props.setShowWelcomeBanner(false);
    }

    return (
        <div className="first-welcome-banner">
            <div className="welcome-banner-text">
                <span style={{fontWeight: 900}}>
                    Account details has been sent<br />
                    to your email
                </span>
            </div>
            <button className="close-banner-button" onClick={onClose}>
                Close
            </button>
        </div>
    );
};

export default SecondBanner;