import React from "react";
import { purchaseEvent } from "../utils/PixelEvents";
import { initiateCheckoutEvent } from "../utils/PixelEvents";

import { fetchFace } from "../utils/utils";

const ContinueBtn = (props) => {

    const handlePayment = (e) => {
        if (!props.premium) {
            e.preventDefault();
            window.localStorage.setItem("faceName", props.faceName);
            window.localStorage.setItem("readyToBuy", true);
            props.setPreview(false);
            props.setPaymentState(true);
            props.setReadyToBuy(true);
            initiateCheckoutEvent();
        } else {
            e.target.classList.add("is-loading");
            e.target.innerText = "Downloading...";
            fetchFace(props.faceName, e.target, props.handleModal);
        }
    };

    return (
        <button
            className="wf-buttons button buy-btn"
            style={{ fontWeight: 500 }}
            variant="contained"
            onClick={handlePayment}
        >
            {props.premium ? "Download" : "Continue"}
        </button>
    );
};

export default ContinueBtn;
