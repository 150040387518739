import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import Payment from "./pages/Payment";

const App = () => {

    return (
        <Routes>
            <Route path="/" element={<Payment />} />
                <Route
                    path="/signup"
                    element={<SignUp />}
                />
                <Route
                    path="/signin"
                    element={<SignIn />}
                />
        </Routes>
    );
};

export default App;
