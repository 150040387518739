import React from 'react';
import bannerStar from '../../images/banner-star.png';

const FirstBanner = (props) => {

    const email = localStorage.getItem('email_signup');

    const onClose = () => {
        props.setShowFirstBanner(false);
        props.setShowSecondBanner(true);
        localStorage.setItem("welcomeBannerStage", "second");
    }

    return (
        <div className="first-welcome-banner">
            <img src={bannerStar} style={{width: "7vw", marginLeft: "3vw"}} alt="First Banner" />
            <div className="welcome-banner-text">
                <span style={{fontWeight: 900}}>Premium Account Created</span>
                <span>{email}</span>
            </div>
            <button className="close-banner-button" onClick={onClose}>
                Close
            </button>
        </div>
    );
};

export default FirstBanner;