import React, {
  useEffect,
  useState,
} from 'react';

import {
  useFormik,
} from 'formik';

import { useSearchParams } from 'react-router-dom';
import Page from './Page';
import { validationSchema } from './validation-scheme';
import useAmplitude from '../../hooks/use-amplitude';
import styles from './style.module.scss';

export default function SignUp() {
  const amplitude = useAmplitude();

  const [
    intentId,
    setIntentId,
  ] = useState();

  const [
    searchParams,
  ] = useSearchParams();

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, formik) => {
      const finishWithCreated = (created) => {
        if (amplitude) {
          amplitude.track('signup_presented', {
            account: values.email,
            account_created: created,
          });
        }
      };

      fetch('/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          premium: !!intentId,
          payment_intent: intentId,
        }),
      }).then((response) => response.json()).then((data) => {
        let errorMessage = 'Access token not found in response';

        if (typeof data === 'object' && data !== null) {
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('email_signup', values.email);
  
          if (data.access_token) {
            const always = () => {
              finishWithCreated(true);
              window.location.href = '/';
            };
            
            if (typeof intentId === 'string') {
              fetch('/onboarding_sub/api/payment-intent/bind-email', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: values.email,
                  id: intentId,
                }),
              }).then(always).catch(always);
            }
            
            return;
          }

          if (data.detail) {
            errorMessage = data.detail;
          }
        }

        throw new Error(errorMessage);
      }).catch((error) => {
        finishWithCreated(false);

        formik.setErrors({
          email: error.message,
        });

        formik.setSubmitting(false);
      });
    },
  });

  useEffect(() => {
    if (amplitude) {
      amplitude.track('signup_presented', {
        account: formik.values.email,
      });
    }
  }, [
    amplitude,
  ]);

  useEffect(() => {
    const email = searchParams.get('email');

    if (email) {
      formik.setFieldValue('email', email);
      localStorage.setItem('email_purchase', email);
    }

    const paymentIntent = searchParams.get('payment_intent');
    setIntentId((typeof paymentIntent === 'string' && paymentIntent.length > 0) ? paymentIntent : undefined);
  }, [
    searchParams,
  ]);

  const [
    showPassword,
    setShowPassword,
  ] = useState(false);

  const onToggleShowPassword = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  const button = (
    <button
      className={styles.action}
      onClick={formik.submitForm}
      disabled={formik.isSubmitting}
      type="button"
    >
      Create Account
    </button>
  );

  let emailClassName = `${styles.input} ${styles.email}`;
  let emailError;

  if (formik.touched.email && formik.errors.email) {
    emailClassName += ` ${styles.hasError}`;
    emailError = (
      <div className={styles.error}>
        {formik.errors.email}
      </div>
    );
  }

  let passwordClassName = `${styles.input} ${styles.password}`;
  let passwordType = 'password';
  let passwordError;

  if (formik.touched.password && formik.errors.password) {
    passwordClassName += ` ${styles.hasError}`;
    passwordError = (
      <div className={styles.error}>
        {formik.errors.password}
      </div>
    );
  }

  if (showPassword) {
    passwordClassName += ` ${styles.show}`;
    passwordType = 'text';
  }

  return (
    <Page button={button}>
      <div className={styles.title}>
        Create Your Account
      </div>
      <div className={styles.subtitle}>
        To access the gallery now and in the future, please enter your login details:
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={emailClassName}>
          <input
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter your email address"
            value={formik.values.email}
          />  
        </div>
        {emailError}
        <div className={passwordClassName}>
          <input
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter your password"
            type={passwordType}
            value={formik.values.password}
          />
          <button
            onClick={onToggleShowPassword}
            type="button"
          />
        </div>
        {passwordError}
      </form>
      <div className={styles.premium}>
        As a premium member, you'll now have unlimited access to download and customize watch faces. New designs will be added regularly for your enjoyment.
      </div>
    </Page>
  );
}