const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const getUserData = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        window.localStorage.setItem("country_code", data.country_code);
        window.localStorage.setItem("country_name", data.country_name);
        window.localStorage.setItem("city", data.city);
        window.localStorage.setItem("region", data.region);
        window.localStorage.setItem("userIp", data.ip);
    } catch (error) {
        console.error('Error fetching location:', error);
        }
    };


const amplitudeEventsSender = async (props) => {
    if (!window.localStorage.getItem('userIp')) {
        await getUserData();
    }

    const properties = {
        ...props.properties, 
        ...{
            email_signup: localStorage.getItem('email_signup'),
            email_purchase: localStorage.getItem('email_purchase'),
        },
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            event_name: props.event_name,
            properties: properties,
            button: props.button,
            country: window.localStorage.getItem('country_name') || '',
            city: window.localStorage.getItem('city') || '',
            region: window.localStorage.getItem('region') || '',
            ip: window.localStorage.getItem('userIp') || '',
            platform: navigator.platform || '',
            os_name: getMobileOperatingSystem()
        })
    };

    const response = await fetch('/tracking/send_amp_event', requestOptions);
    const data = await response.json();
    if (! response.ok) {
        // console.log("Error")
    } else {
        console.log(data.status);
    }
}

export default amplitudeEventsSender;