import React from "react";
import WatchfacesGallery from "./WatchfacesGallery";
import amplitudeEventsSender from "./utils/AmplitudeEvents";

class WatchfacesGalleryPanel extends React.Component {
    // const [images, setImages] = useState([]);
    state = {
        producerLoginRedirectEndPoint: "/auth/login-redirect",
        producerLoginEndPoint: "/auth/login/",
        producerLogoutEndpoint: "/auth/logout/",
        producerLoginCheckEndpoint: "/auth/user-session-status/",
        userLoggedIn: false,
        login: false,
        userName: null,
        facesLen: null,
        faces: [],
        accessToken: null,
        facesUrl: null,
        strapsUrl: null,
        activeMainModal: false,
        userToken: null,
        preloaded: {},
        formatsOfFaces: {},
    };

    componentDidMount() {
        // this.authenticate();
        this.getDictionaryOfImages();
        this.addNewClient();
    }

    getFbClientId = () => {
        let result = /_fbp=(fb\.\d\.\d+\.\d+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
            return null;
        }
        return result[1];
    };

    addFacebookIdToClient = async () => {
        let fbClientId;
        setTimeout(async () => {
            fbClientId = this.getFbClientId();
            if (fbClientId) {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const response = await fetch("/api/add_data_to_client", requestOptions);
                const data = await response.json();
                if (!response.ok) {
                    console.log("failed");
                } else {
                    // console.log(data);
                }
            }
        }, 3000);
    };

    addNewClient = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch("/api/add_new_client", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Failed to add new client");
        } else {
            this.setState({
                userToken: data.token,
            });
            amplitudeEventsSender({ event_name: "page_loaded" });
            this.addFacebookIdToClient();
        }
    };

    responseFacebook = (code) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ code: code }),
        };
        fetch("/auth/facebook-login-callback", requestOptions)
            .then((response) => {
                console.log(response);
                if (response.url) {
                    let authToken = (response.url.match(/authToken=([^&]+)/) || [])[1];
                    if (authToken) {
                        this.getAccessToken(authToken);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getDictionaryOfImages = async () => {

        const premium = window.location.pathname.includes("premium");

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        let response;
        if (!premium) {
            response = await fetch("/api/get_images", requestOptions);
        } else {
            response = await fetch("/api/get_images_premium", requestOptions);
        }
        const data = await response.json();
        if (!response.ok) {
            // TODO: SHOW SOMETHING
        } else {
            this.setState(
                {
                    faces: data.faces,
                    facesLen: data.facesLen,
                    facesUrl: data.facesUrl,
                    strapsUrl: data.strapsUrl,
                },
                this.preloadImages
            );
        }
    };

    preloadImages = () => {
        const { facesUrl, strapsUrl, faces } = this.state;
        const batchSize = 10;
        let currentBatchStartIndex = 0;

        const strapImg = new Image();
        strapImg.src = `${strapsUrl}Black.webp`;
        this.setState({
            preloaded: {strap: strapImg}
        })

        const formats = {};
        const newPreloaded = { ...this.state.formatsOfFaces };
        for (let i = 0; i < faces.length; i++) {
            formats[faces[i].face_name] = faces[i].format;
            Object.assign(newPreloaded, formats);
        }
        this.setState({
            formatsOfFaces: newPreloaded,
        });

    };

    setCookie = (cname, cvalue, exdays) => {
        const expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + expireDate.toUTCString();
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    };

    getCookie = (cname) => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    authenticate = () => {
        const authToken = (window.location.search.match(/authToken=([^&]+)/) || [])[1];
        const code = (window.location.search.match(/code=([^&]+)/) || [])[1];
        window.history.pushState("object", document.title, "/");

        if (authToken) {
            this.getAccessToken(authToken);
        } else if (code) {
            this.responseFacebook(code);
        } else {
            this.checkUserSessionStatus();
        }
    };

    getAccessToken = (authToken) => {
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + authToken,
            },
            credentials: "include",
        };

        fetch(this.state.producerLoginEndPoint, requestOptions)
            .then((response) => {
                this.checkUserSessionStatus();
            })
            .then((data) => {})
            .catch((err) => {});
    };

    checkUserSessionStatus = () => {
        const requestOptions = {
            method: "GET",
            credentials: "include",
        };

        fetch(this.state.producerLoginCheckEndpoint, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState(
                    {
                        userLoggedIn: data.userLoggedIn,
                        userName: data.userName,
                    },
                    () => {
                        if (this.state.userLoggedIn) {
                            if (
                                window.localStorage.getItem("faceName") !== undefined &&
                                window.localStorage.getItem("readyToBuy")
                            ) {
                                this.setActiveMainModal();
                            }
                        }
                    }
                );
            })
            .catch((err) => {});
    };

    logout = () => {
        const requestOptions = {
            method: "GET",
            credentials: "include",
        };

        fetch(this.state.producerLogoutEndpoint, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.reload();
            })
            .catch((err) => {});
    };

    handleMainModal = () => {
        this.setState({
            activeMainModal: false,
        });
    };

    setActiveMainModal = () => {
        this.setState({
            activeMainModal: true,
        });
    };

    setLoginState = () => {
        this.setState({
            login: true,
            activeMainModal: true,
        });
    };

    render() {
        const {
            setShowBanner,
            setSignedIn,
            showChangeEmailPopup,
            setShowChangeEmailPopup
        } = this.props
        return (
            <>
                <div>
                </div>
                <div>
                    <WatchfacesGallery
                        facesLen={this.state.facesLen ? this.state.facesLen : 0}
                        faces={this.state.faces ? this.state.faces : []}
                        facesUrl={this.state.facesUrl ? this.state.facesUrl : ""}
                        strapsUrl={this.state.strapsUrl ? this.state.strapsUrl : ""}
                        userLoggedIn={this.state.userLoggedIn}
                        setMainModal={this.setActiveMainModal}
                        preloaded={this.state.preloaded}
                        formats={this.state.formatsOfFaces}
                        // for modals
                        login={this.state.login}
                        activeModalState={this.state.activeMainModal}
                        setActiveMainModal={this.setActiveMainModal}
                        handleModal={this.handleMainModal}
                        redirectEndpoint={this.state.producerLoginRedirectEndPoint}
                        facebookCallback={this.responseFacebook}
                        checkUserSessionStatus={this.checkUserSessionStatus}
                        setShowBanner={this.props.setShowBanner}
                        setSignedIn={this.props.setSignedIn}

                        showChangeEmailPopup={this.props.showChangeEmailPopup}
                        setShowChangeEmailPopup={this.props.setShowChangeEmailPopup}
                    />
                </div>
            </>
        );
    }
}

export default WatchfacesGalleryPanel;
