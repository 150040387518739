import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { purchaseEvent } from "../utils/PixelEvents";

import SecureImage from "../../images/secure.svg"

const StripeCheckoutForm = ({ clientSecret, urlPart, hashedValue, setErrorComponentState, setPaymentFailed, setStripeError, priceToMeta, test, country }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const options = {
        // layout: {
        //     type: 'accordion',
        //     defaultCollapsed: false,
        //     radios: false,
        //     spacedAccordionItems: true
        // },
        paymentMethodOrder: ['card'],
        fields: {
            billingDetails: {
                address: {
                    country: 'never'
                }
            }
        }
    }


    useEffect(() => {
        if (!stripe) {
            return;
        }

        const intentClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!intentClientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(intentClientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        // purchaseEvent();

        setIsLoading(true);

        const countryCode = window.localStorage.getItem("country_code");


        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://watchfaces.mobitopia.co`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: countryCode
                        }
                    }
                }
            },
            // redirect: 'if_required',
        });

        if (!error) {
            console.log("NO ERRORS");
            Array.from(document.querySelectorAll(".add-get-button")).forEach((element) => {
                element.classList.add("get-face-btn");
                element.innerText = "Download";
            });
            document.cookie = 'bought=true;max-age=31536000';
            purchaseEvent(priceToMeta);
            return;
        }
        if (error && (error.type === "card_error" || error.type === "validation_error")) {
            setMessage(error.message);
            setStripeError(error.message);
            if (error.code === 'card_declined') {
                setErrorComponentState(true);
                setPaymentFailed(true);
                setStripeError(error.message);
            }
        } else {
            setErrorComponentState(true);
            setPaymentFailed(true);
            setMessage("An unexpected error occurred.");
            setStripeError("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={options} />
            <button disabled={isLoading || !stripe || !elements} id="submit" style={{color: "white", background: "black"}} className="wf-buttons button">
                <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
            </button>
            {message && <div id="payment-message">{message}</div>}

        </form>
    );
};

export default StripeCheckoutForm;
