import React, { Fragment, useEffect, useState } from 'react';
import useUser from '../hooks/use-user';

const MenuComponent = ({setShowChangeEmailPopup, closeMenu}) => {
    const user = useUser();

    const [
        canManagePlan,
        setCanManagePlan,
    ] = useState(false);

    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        const email_signup = localStorage.getItem("email_signup");
        if (typeof email_signup === "string" && email_signup !== "undefined") {
            console.log("Email Signup ", email_signup);
            setSignedIn(!!email_signup);
        }
    }, []);

    useEffect(() => {
        const hideButton = () => {
            if (window.FreshworksWidget) {
                window.FreshworksWidget('hide');
            } else {
                setTimeout(hideButton, 10);
            }
        }

        hideButton();
    });

    useEffect(() => {
        let unmounted = false;

        if (user) {
            const finish = (answer) => {
                if (unmounted) {
                    return;
                }

                let newCanManagePlan = false;

                if (typeof answer === 'object' && answer !== null) {
                    if (Array.isArray(answer.subscriptions)) {
                        const subscription = answer.subscriptions[0];
                        newCanManagePlan = subscription.status !== 'trialing';
                    }
                }

                setCanManagePlan(newCanManagePlan);
            };

            fetch(`/onboarding_sub/api/user-subscriptions?email=${user.email}`, {
                method: 'GET',
            }).then((response) => {
                response.json().then(finish).catch(finish);
            }).catch(finish);
        }

        return () => {
            unmounted = true;
        };
    }, [
        user,
    ]);

    const logOut = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("email_signup");
        window.location.href = '/';
    }

    const openForm = () => {
        window.FreshworksWidget('open');
    }

    const onChangeEmail = () => {
        setShowChangeEmailPopup(true);
        closeMenu();
    }

    return (
        <div className='menu-comp mt-4' style={{marginLeft: "5vw", marginRight: "5vw", display: 'flex', flexDirection: 'column'}}>
            {signedIn ? (
                <Fragment>
                    <div className="menu-item">
                        Account
                    </div>
                    <div className="menu-item">
                        <div style={{display: "flex", flexDirection:"row", alignItems: "center"}}>
                            <span style={{fontWeight: 400, color: "#FFFFFFCC", fontSize: "5.5vw"}} onClick={onChangeEmail}>{localStorage.getItem("email_signup")}</span>
                            <div style={{background: "linear-gradient(#FFC26A, #795324, #DF8A25)", marginLeft: "10px", paddingLeft: "7px", paddingRight: "7px", borderRadius: "7px"}}>
                                Premium
                            </div>
                        </div>
                    </div>
                    {canManagePlan && (
                        <a 
                            className="menu-item"
                            href="billing.stripe.com/p/login/14kg1j07G7Yj3wQ9AA"
                        >
                            Watch Faces Gallery
                        </a>
                    )}
                </Fragment>
            ) : (
                <a id="auth" className="menu-item" href="/signin">
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <span>Sign In</span>
                        <div style={{background: "linear-gradient(#FFC26A, #795324, #DF8A25)", marginLeft: "10px", paddingLeft: "7px", paddingRight: "7px", borderRadius: "7px"}}>
                            Premium
                        </div>
                    </div>
                </a>
            )}
            <a id="home" className="menu-item" href="/">
                Watch Faces Gallery
            </a>
            <a id="how-to" className="menu-item" href="https://watchfacesgallery.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">
                How-to
            </a>
            <div className="menu-item" onClick={openForm} id="contact">
                Contact Us
            </div>
            <a id="policy" className="menu-item" href="/privacy-policy">
                Privacy Policy
            </a>
            <a id="terms" className="menu-item" href="/terms-of-use">
                Terms of Use
            </a>
            {signedIn && (
                <div id="logout" className="menu-item" onClick={logOut}>
                    Logout
                </div>
            )}
        </div>
    );
};

export default MenuComponent;