import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import StripeCheckoutForm from "../forms/StripeCheckoutForm";
import StripeCheckoutForm2 from "../forms/StripeCheckoutForm2";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import amplitudeEventsSender from "../utils/AmplitudeEvents";
import StripeErrorComponent from "./StripeErrorComponent";
import Vector from "../../images/Vector.png"
import CardPayment from "../../images/card-payment.png";
import ExpressFiller from "../../images/express-pay-filler.png";



const stripePromise = loadStripe(
    // "pk_test_51MCMD3FLF5P5YxU3Cfaf9OqugIqixVlNO5h2SxeNcHoLBFaOrrPTe0Mz43t9oxL7HuqUiG5s7Z3KehSW62rBl87U00ugIP8ZUT"
    "pk_live_51MCMD3FLF5P5YxU3MADNQa6uDEpZfr7y2GdktJT7shnNEs8jQ2TLzpwDxWrwlVgd3Qpzmg0fJnMBWjAIiJ6pJtPF00dl3xHDWt"
);

const StripeComponent = ( props ) => {
    const [clientSecret, setClientSecret] = useState("");
    const [urlPart, setUrlPart] = useState("");
    const [hashedValue, setHashedValue] = useState("");

    const [stripeError, setStripeError] = useState("");

    const [priceToShow, setPriceToShow] = useState("");

    const [priceToMeta, setPriceToMeta] = useState("");

    const [paymentFailed, setPaymentFailed] = useState(false);

    const [errorComponentState, setErrorComponentState] = useState(false);

    const [useCard, setUseCard] = useState(false);


    const [showCardButton, setShowCardButton] = useState('hidden');

    const [checkoutIsReady, setCheckoutIsReady] = useState(false);

    const handleCardPayClick = () => {
        setUseCard(!useCard);
        setShowCardButton('none');
    }


    useEffect(() => {
        const fetchCreatePayment = async () => {
            if (!props.readyToBuy && !window.localStorage.getItem("readyToBuy")) {
                return;
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: props.faceName ? props.faceName : (window.localStorage.getItem("faceName") || "banner") }),
            };
            amplitudeEventsSender({
                event_name: 'payment_screen_requested'
            });
            const response = await fetch("/api/create-payment-intent", requestOptions);
            const result = await response.json();
            setClientSecret(result.clientSecret);
            setUrlPart(result.urlPart);

            setHashedValue(result.hashedValue);

            setPriceToMeta(result.priceToMeta);
            document.cookie = `priceToMeta=${result.priceToMeta}`;
            setPriceToShow(result.priceToShow);
            setShowCardButton("initial");
            amplitudeEventsSender({
                event_name: 'payment_screen_loaded'
            });
            window.localStorage.removeItem("readyToBuy");
            window.localStorage.removeItem("faceName");
        };

        fetchCreatePayment().catch(console.error);
    }, [props.faceName, setHashedValue, props.readyToBuy]);

    const appearance = {
        theme: 'night',
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#0A2540',
            colorPrimary: '#EFC078',
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#727F96',
            tabIconColor: 'white',
            logoColor: 'dark'
        },
        rules: {
            '.Input, .Block': {
            backgroundColor: 'transparent',
            border: '1.5px solid var(--colorPrimary)'
            }
        }
    };

    const options = { clientSecret, appearance };

    return (
        <>
            {!paymentFailed
            ? (
                <>
                    <div>
                        <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important", fontWeight: 700, fontSize: "6vw"}} className="payment-header mb-2" variant="h6" component="h2">
                            <p>Get All Watch Faces</p>
                        </Typography>
                        <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important", lineHeight: "6vw"}} className="payment-header" variant="h8" component="h2">
                            <p>Complete Watch Faces Collection bundle including Dynamic, Live, Portrait, and Static Watch Faces, with weekly updates</p>
                        </Typography>
                    </div>
                    <div className="mt-5 mb-4" style={{borderTop: "0.2vw solid white", borderBottom: "0.2vw solid white", fontSize: "4.5vw", color: "white"}}>
                        <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                                Lifetime Access to all collection
                            </div>
                            <div>
                                $19.99
                            </div>
                        </div>
                        <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                                New Watch Faces every week
                            </div>
                            <div>
                                $0.00
                            </div>
                        </div>
                        <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                                Semi-annual discount
                            </div>
                            <div>
                                <div style={{backgroundColor: "white", color: "black", paddingLeft: "1vw", paddingRight: "1vw"}}>
                                    – $13.00
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-4" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", color: "white", fontSize: "5.5vw", fontWeight: 700}}>
                        <div>
                            Total
                        </div>
                        <div>
                            $6.99
                        </div>
                    </div>
                    <div className="guarantee-part mt-4 w-100 mb-4">
                        <img style={{width: "4vw"}} src={Vector} alt=""/>
                        <span style={{fontSize: "3.5vw"}}>30-day money-back guarantee</span>
                        <div className="stripe-text-block">
                            Powered by <span style={{fontWeight:"700"}}>stripe</span>
                        </div>
                    </div>
                </>
            ) : (
                <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important"}} className="payment-header" variant="h6" component="h2">
                    <p>❌ Oops, Payment is failed</p>
                </Typography>
                )
            }
            {!errorComponentState 
            ? (
                <Typography id="transition-modal-description" sx={{ mt: 2 }} component={"span"}>
                    <div className="loader-cards" style={{display: !checkoutIsReady ? 'initial' : 'none'}}></div>
                        <div className="payments-filler" style={{display: !checkoutIsReady ? 'initial' : 'none', filter: 'blur(3px)', background: '#000000', opacity: '0.4'}}>
                            <img style={{marginTop: "2vw"}} src={ExpressFiller} alt="" />
                            <img style={{marginTop: "2vw"}} src={CardPayment} alt="" />
                        </div>
                    <div className="checkout-forms" style={{display: checkoutIsReady ? 'initial' : 'none'}}>
                        {clientSecret && !useCard && (
                            <Elements options={options} stripe={stripePromise} key={clientSecret}>
                                <StripeCheckoutForm2 
                                urlPart={urlPart} 
                                hashedValue={hashedValue} 
                                setErrorComponentState={setErrorComponentState}
                                setPaymentFailed={setPaymentFailed}
                                setStripeError={setStripeError}
                                setCheckoutIsReady={setCheckoutIsReady}
                                bannerOffer={props.bannerOffer}
                                />
                            </Elements>
                        )}
                        {clientSecret && useCard && (
                            <Elements options={options} stripe={stripePromise} key={clientSecret}>
                                <StripeCheckoutForm
                                clientSecret={clientSecret}
                                urlPart={urlPart} 
                                hashedValue={hashedValue} 
                                setErrorComponentState={setErrorComponentState}
                                setPaymentFailed={setPaymentFailed}
                                setStripeError={setStripeError}
                                bannerOffer={props.bannerOffer}
                                />
                            </Elements>
                        )}
                        {!clientSecret && <div className="spinner" id="spinner"></div>}
                        <div style={{display: showCardButton}}>
                            <button style={{marginTop: "4vw"}} onClick={handleCardPayClick} className="wf-buttons button">
                                <span id="button-text">Continue with card</span>
                            </button>
                        </div>
                    </div>
                </Typography>
            ) : (
                <>
                    <StripeErrorComponent 
                        setPaymentFailed={setPaymentFailed}
                        setErrorComponentState={setErrorComponentState}
                        stripeError={stripeError}
                    />
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise} key={clientSecret}>
                            <StripeCheckoutForm
                            clientSecret={clientSecret}
                            urlPart={urlPart} 
                            hashedValue={hashedValue} 
                            setErrorComponentState={setErrorComponentState}
                            setPaymentFailed={setPaymentFailed}
                            setStripeError={setStripeError}
                            />
                        </Elements>
                    )}
                </>
            )
            }
        </>
    );
};

export default StripeComponent;
