import React from 'react';
import Typography from "@mui/material/Typography";

const StripeErrorComponent = (props) => {

    const handlePaymentAgain = (e) => {
        props.setPaymentFailed(false);
        props.setErrorComponentState(false);
    }

    return (
        <>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <div className="mt-6" style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                    <img src={require("../../images/logo.png")} alt="" style={{width: "126px", height: "128px"}}></img>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="span">
                <div className="mt-6 download-text" style={{fontFamily: "'Product Sans', sans-serif !important", fontSize: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', color: "white", textAlign: "center"}}>
                    <p>Sorry! There was an error processing your payment.</p>
                    <p className="mt-4 mb-6">{props.stripeError}</p>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="span">
            </Typography>
        </>
    );
};

export default StripeErrorComponent;