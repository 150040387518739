import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import Item from "../style_components/Item";
import amplitudeEventsSender from "../utils/AmplitudeEvents"
import ContinueBtn from '../buttons/ContinueBtn';
import Vector from "../../images/Vector.png"
import SatisfiedUsers from "../../images/userssatisfied.png";
import Complectable from "../../images/complectable.png";


class Container extends React.Component {
    render() {
            return <div ref={ ref => ref ?  ref.appendChild(this.props.child) : "" }></div>;
    }
  }


const PreviewComponent = ( props ) => {

    const buttonStyle = {
        position: 'absolute',
        left: "0px",
        top: "0px",
        zIndex: 1,
        width: "100%",
        height: "100%",
        margin: "0px",
        padding: "0px",
        border: "none",
        fontSize: "0px",
        background: "transparent",
        cursor: "pointer"
    }

    // const [previewUrl, setPreviewUrl] = useState("");
    // const premium = document.cookie.split(";").some((item) => item.includes("bought=true"));
    const [previewFormat, setPreviewFormat] = useState("")

    useEffect(() => {
        setPreviewFormat(props.formats[props.faceName]);
        amplitudeEventsSender({
            event_name: 'preview_screen_presented',
            properties: {
                itemID: props.faceName
            }
        });
    }, [props.faceName]);
    return (
        <>
            <Typography id="transition-modal-title" style={{fontFamily: "'Product Sans', sans-serif !important"}} variant="h6" component="h2">
                <Item style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0}}>
                    <div className="preview-header-container">
                        <div className="centered-content">{props.imageName}</div>
                        <div className="close-button">
                            <button type="button" onClick={props.handleModal} style={buttonStyle}></button>
                            <span>
                                <span className="bm-burger-cross-preview" style={{position: "absolute", transform: "rotate(45deg)"}}></span>
                                <span className="bm-burger-cross-preview" style={{position: "absolute", transform: "rotate(-45deg)"}}></span>
                            </span>
                        </div>
                    </div>
                    <div style={{position: "relative"}}>
                        <div>
                            {previewFormat === "video" ? (
                                <div className="video-container-preview">
                                    <video className="preview-video" 
                                        muted playsInline autoPlay loop
                                        poster={`${props.facesUrl}posters/${props.faceName}.webp`}
                                        >
                                        <source src={`${props.facesUrl}${props.faceName}.mov`} type="video/mp4" />
                                    </video>
                                    <div className="overlay">
                                        <p className="overlay-date">FRI 23</p>
                                        <p className="overlay-time">10:09</p>
                                    </div>
                                </div>
                            ) : (
                                <img className="preview-image" src={`${props.facesUrl}${props.faceName}.webp`} alt="" />
                            )}
                        </div>
                        <Container child={props.preloaded.strap} />
                    </div>
                    {!props.premium && (
                        <>
                            <div className="offer-text">
                                Unlock this Watch Face<br />and get the entire collection for free
                            </div>
                            <div className="offer-price">
                                <div className="old-price">
                                    $19.99
                                </div>
                                <div className="mr-2">
                                    $6.99
                                </div>
                                <div className="sale">
                                    SALE
                                </div>
                            </div>
                        </>
                    )}
                    {props.premium && (
                        <div className="mt-6 mb-6"></div>
                    )}
                    <ContinueBtn 
                        premium={props.premium}
                        faceName={props.faceName}
                        handleModal={props.handleModal}
                        // setActiveModal={setActiveModal}
                        setReadyToBuy={props.setReadyToBuy}
                        userLoggedIn={props.userLoggedIn}
                        setPreviewComponentState={props.setPreviewComponentState}
                        setPreview={props.setPreview}
                        setStripeComponentState={props.setStripeComponentState}
                        setPaymentState={props.setPaymentState}
                    />
                    <div className="guarantee-part-preview mt-4">
                        <img style={{width: "3.5vw"}} src={Vector} alt=""/>
                        <span style={{marginLeft: "1vw"}}>30-day money-back guarantee</span>
                    </div>
                    <div className="guarantee-part-preview mt-4">
                        <img style={{width: "29vw", marginRight: "3vw"}} src={SatisfiedUsers} alt = "" />
                        <img style={{marginLeft: "3vw", width: "40vw"}} src={Complectable} alt="" />
                    </div>
                </Item>
            </Typography>
        </>
    );
};

export default PreviewComponent;