import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Item from "./style_components/Item"; // Import your Item component
import PreviewButton from "./buttons/PreviewButton"; // Import your PreviewButton component
import FirstBanner from "./banners/FirstBanner";
import SecondBanner from "./banners/SecondBanner";


const PaginationComponent = (props) => {
    const batchSize = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedFaces, setDisplayedFaces] = useState([]);

    const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);

    const [showFirstBanner, setShowFirstBanner] = useState(true);

    const [showSecondBanner, setShowSecondBanner] = useState(false);

    useEffect(() => {
        const startIndex = (currentPage - 1) * batchSize;
        const endIndex = startIndex + batchSize;
        const newBatch = props.faces.slice(startIndex, endIndex);
        setDisplayedFaces((prevFaces) => [...prevFaces, ...newBatch]);
    }, [currentPage, props.faces]);

    const handleShowMore = () => {
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        const welcomeBannerStage = localStorage.getItem("welcomeBannerStage");
    
        console.log("Welcome Banner Stage from localStorage:", welcomeBannerStage);
    
        if (welcomeBannerStage) {
            console.log("GOT IT");
            setShowWelcomeBanner(true);
    
            switch (welcomeBannerStage) {
                case "first":
                    setShowFirstBanner(true);
                    setShowSecondBanner(false);
                    break;
                case "second":
                    setShowFirstBanner(false);
                    setShowSecondBanner(true);
                    break;
                default:
                    setShowWelcomeBanner(false);
                    setShowFirstBanner(false);
                    setShowSecondBanner(false);
            }
        }
    }, []);

    return (
        <>
            {showWelcomeBanner && 
                <div className="welcomeBanner">
                    {showFirstBanner && 
                        <FirstBanner
                            setShowFirstBanner={setShowFirstBanner}
                            setShowSecondBanner={setShowSecondBanner}
                        />
                    }
                    {showSecondBanner && 
                        <SecondBanner 
                            setShowFirstBanner={setShowFirstBanner}
                            setShowSecondBanner={setShowSecondBanner}
                            setShowWelcomeBanner={setShowWelcomeBanner}
                        />
                    }
                </div>
            }
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 0.2, md: 2 }} columns={{ xs: 3, sm: 4, md: 6, lg: 8, xl: 11 }}>
                    {displayedFaces.map((image, index) => (
                        <Grid item xs={1} sm={1} md={1} lg={1} key={index}>
                            <Item>
                                <div className="mb-2 cut-text" style={{ fontFamily: "'Product Sans', sans-serif !important", height: "25px" }}>
                                    {image.name}
                                </div>
                                <PreviewButton
                                    render={true}
                                    image={image}
                                    facesUrl={props.facesUrl}
                                    strapsUrl={props.strapsUrl}
                                    imageName={image.name}
                                    faceName={image.face_name}
                                    setStrap={props.setStrap}
                                    setActiveMainModal={props.setActiveMainModal}
                                    setPreviewComponentState={props.setPreviewComponentState}
                                    setFaceName={props.setFaceName}
                                    setImageName={props.setImageName}
                                    userLoggedIn={props.userLoggedIn}
                                    premium={props.premium}
                                />
                            </Item>
                        </Grid>
                    ))}
                </Grid>
                {displayedFaces.length < props.faces.length && (
                    <div className="h-100 mt-2 mb-6 d-flex align-items-center justify-content-center">
                        <button onClick={handleShowMore} className="button show-more-button wf-buttons">Show more</button>
                    </div>
                    )}
            </Box>
        </>
    );
};

export default PaginationComponent;
