import React, { useState } from "react";

const ImageComponent = ({ render, image, facesUrl, strapsUrl, handleClick }) => {
    const isVideo = image.format === "video";

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoaded = () => {
        // This function will be called when the video data is loaded
        setIsVideoLoaded(true);
    };

    const handleImageClick = () => {
        handleClick(image); // Assuming handleClick is a function passed as a prop
    };

    return (
        <>
            {isVideo ? (
                <div className="video-container">
                    <video
                        onClick={handleImageClick}
                        preload="auto"
                        // controls
                        poster={`${facesUrl}posters/${image.face_name}.webp`}
                        loop
                        playsInline
                        width="300"
                        autoPlay
                        muted
                        style={{
                            borderRadius: "15.2274px",
                            filter: "drop-shadow(6px 4px 14px rgba(0, 0, 0, 0.25))",
                        }}
                    >
                        <source src={`${facesUrl}${image.face_name}.mov`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div onClick={handleImageClick} className="overlay">
                        <p className="overlay-date">FRI 23</p>
                        <p className="overlay-time">10:09</p>
                    </div>
                </div>
            ) : (
                <div className="video-container">
                    <img
                        onClick={handleImageClick}
                        src={`${facesUrl}${image.face_name}.webp`}
                        alt={image.face_name}
                        style={{
                            borderRadius: "15.2274px",
                            filter: "drop-shadow(6px 4px 14px rgba(0, 0, 0, 0.25))",
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default ImageComponent;
