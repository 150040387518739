import React from 'react';
import styles from './style.module.scss';

export default function Onboarding({ children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.inner}>
          {children}
        </div>
      </div>
    </div>
  );
}