import React, { useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PreviewComponent from "../modal_components/PreviewComponent";
import StripeComponent from "../modal_components/StripeComponent";



const MainModal = ( props ) => {

    const [previewComponentState, setPreviewComponentState] = useState(false);
    const [, setStripeComponentState] = useState(false);


    const style = {
        position: "absolute",
        // top: top,
        top: "56%",
        // bottom: bottom,
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "100%",
        width: "50%",
        maxWidth: "554px",
        height: "100%",
        minHeight: "600px",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        // background: "linear-gradient(180deg, #282828 0%, #101010 100%)",
        // borderRadius: borderRadius,
        background: "#000000",
        outline: 0,
        paddingTop: "12px"
    };

    const handleMainModal = () => {
        setPreviewComponentState(false);
        setStripeComponentState(false);
        props.handleModal();
    }

    useEffect(() => {
        if (props.login && (!props.preview)) {
            setPreviewComponentState(false);
            setStripeComponentState(false);
        } else if (props.preview) {
            setPreviewComponentState(true);
            setStripeComponentState(false);
            props.setBannerOffer(false);
        } else if (props.payment) {
            setPreviewComponentState(false);
            setStripeComponentState(true);
        }
    }, [props.login, props.preview, props.payment, props]);


    return (
        <>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.activeModalState}
            onClose={props.handleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            hideBackdrop={true}
            >
                <Fade in={props.activeModalState}>
                    <Box sx={style}>
                        {previewComponentState ?
                            <PreviewComponent
                                formats={props.formats}
                                active={previewComponentState}
                                imageName={props.imageName}
                                faceName={props.faceName}
                                userLoggedIn={props.userLoggedIn}
                                // setPreviewComponentState={setPreviewComponentState}
                                setPreview={props.setPreview}
                                // setStripeComponentState={setStripeComponentState}
                                setReadyToBuy={props.setReadyToBuy}
                                setPaymentState={props.setPaymentState}
                                preloaded={props.preloaded}
                                strap={props.strap}
                                facesUrl={props.facesUrl}
                                strapsUrl={props.strapsUrl}
                                handleModal={props.handleModal}
                                premium={props.premium}
                            /> :
                            <div style={{ maxHeight: "665px", overflowY: "auto", overflowX: "hidden" }}>
                                <StripeComponent
                                faceName={props.faceName}
                                readyToBuy={props.readyToBuy}
                                bannerOffer={props.bannerOffer}
                                />
                            </div>
                        }
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default MainModal;