export const getTokenValue = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
}


export const fetchFace = async (faceName, button, handleModal = null) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const result = await fetch(`/api/download-face/${faceName}`, requestOptions);
    console.log(result);
    if (!result.ok) {
        return;
    }
    const blob_res = await result.blob();
    const href = window.URL.createObjectURL(blob_res);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${faceName}.watchface`);
    document.body.appendChild(link);
    link.setAttribute("target", "_blank");
    link.click();
    document.body.removeChild(link);
    button.classList.remove("is-loading");
    if (handleModal !== null) {
        handleModal();
        button.innerText = "Continue";
    }
};