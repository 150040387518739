import React from 'react';
import ImageComponent from "../ImageComponent";
import amplitudeEventsSender from "../utils/AmplitudeEvents";
import {viewContentEvent} from "../utils/PixelEvents";
import { fetchFace } from '../utils/utils';

const PreviewButton = (props) => {


    const openPreviewModal = async () => {
        if (!props.premium) {
            props.setActiveMainModal(true);
            props.setPreviewComponentState(true);
            props.setFaceName(props.faceName.split(".")[0]);
            props.setImageName(props.imageName);
            amplitudeEventsSender({
                event_name: 'watch_face_pressed',
                properties: {
                    faceID: props.faceName
                }
            });
            props.setStrap("Black");
            viewContentEvent();
            console.log(props.faceName);
        } else {
            const button = document.getElementById(props.faceName);
            button.click();
        }
    }

    const handleBtnClick = (e) => {
        if (!e.target.classList.contains("get-face-btn")) {
            openPreviewModal();
        } else {
            e.target.classList.add("is-loading");
            fetchFace(props.faceName, e.target);
        }
    }

    const setLoader = (e) => {
        e.target.classList.add("is-loading");
        setTimeout(() => {
            e.target.classList.remove("is-loading");
        }, 1500);
    }

    return (
        <>
            {/* <div onClick={(e) => openPreviewModal()}> */}
            {props.render ? (
                <div>
                <ImageComponent
                image={props.image}
                facesUrl={props.facesUrl}
                handleClick={openPreviewModal}
                />
            </div>
            ) : (
                <div className="loader"></div>
            )}
            {props.premium ? (
                <a id={props.faceName} href={props.render ? `${window.location.origin}/api/get-file/${props.faceName}.watchface` : ""} onClick={setLoader} className="wf-buttons button preview-btn add-get-button get-face-btn" >
                    Download
                </a>
            ) : (
                <button id={props.faceName} onClick={props.render ? handleBtnClick : () => {}} className="wf-buttons button preview-btn add-get-button" >
                    Add &nbsp; +
                </button>
            )}
        </>
    );
};

export default PreviewButton;